import React, { useEffect, useState, useRef, Suspense, useContext } from 'react';
import "./ProductDetail.css";
import "../../components/EnquiryModal/EnquiryModal.css"
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { useForm, Controller } from 'react-hook-form';
import { AppContext } from '../../AppContext';
import Slider from "react-slick";
import { ColorRing } from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RxCross2 } from "react-icons/rx";
import { MdDownload } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));



const ProductDetail = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const [error, setError] = useState(null);
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            enquiry_type: '1'
        }
    });
    const { states, city, setSelectedState } = useContext(AppContext);
    const [product, setProduct] = useState("")
    const [productId, setProductId] = useState("")
    const [isModalOpen, setModalOpen] = useState(false);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [title, setTitle] = useState('');
    const Navigate = useNavigate()
    const { slug } = useParams()
    const sectionRef = useRef(null);

    useEffect(() => {
        if (data?.partner_details?.title) {
            setTitle(data.partner_details.title);
        } else {
            setTitle('Leoni');
        }
    }, [data]);

    const handleFormInteraction = () => {
        if (!showReCAPTCHA) {
            setShowReCAPTCHA(true);
        }
    };
    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const scrollTotop = () => {
        window.scroll(0, 0)
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const selectedState = watch('state')
    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            closeModal()
        }
    };

    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();
            if (response.ok) {
                reset();
                setModalOpen(false);
                Navigate("/thank-you");
            } else {
                if (responseData.msg && responseData.msg === 'Invalid CAPTCHA. Please try again') {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.msg);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    const settings = {
        customPaging: function (i) {
            return (
                <a className='page-img'>
                    {loading ? <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                    /> : (
                        <img src={data?.gallery[i]?.image} alt={`Thumb ${i + 1}`} />
                    )
                    }
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {

        const fetchData = async () => {
            setError(null);
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/product/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (!result?.data) {
                    throw new Error('Invalid slug');
                }
                setData(result?.data);
                setProduct(result?.data.title)
                setProductId(result?.data.id)
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (error) {
        return <NotFoundPage />;
    }

    const handleBreadcrumbClick = () => {
        window.history.back();
    };



    return (
        <>
            <Helmet>
                <meta name="description" content={data?.meta_description} />
                <title>{data?.meta_title}</title>
                <link rel="canonical" href={`https://kesrinandan.com/product-detail/${slug}`} />

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`https://kesrinandan.com/product-detail/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description} />
                <meta property="og:image" content={data?.image} />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="kesrinandan.com" />
                <meta property="twitter:url" content={`https://kesrinandan.com/product-detail/${slug}`} />
                <meta name="twitter:title" content={data?.meta_title} />
                <meta name="twitter:description" content={data?.meta_description} />
                <meta name="twitter:image" content={data?.image} />

                {/* Product Schema */}
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org/",
                            "@type": "Product",
                            "name": `${data?.title}`,
                            "image": `${data?.image}`,
                            "url": `https://kesrinandan.com/product-detail/${slug}`,
                            "title": `${data?.meta_title}`,
                            "description": `${data?.meta_description}`,
                            "brand": {
                                "@type": "Brand",
                                "name": `${data?.partner_details?.slug[0]?.toUpperCase() + data?.partner_details?.slug.slice(1)}`
                            },
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "5",
                                "bestRating": "5",
                                "worstRating": "1",
                                "ratingCount": "2"
                            }
                        }
                    )}
                </script>
            </Helmet>

            <section className="about-banner product-detail-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title'>
                        {title}
                        {data?.partner_details?.title && <span className='color-dot'>.</span>}
                    </p>
                    <Breadcrumb paths={[
                        { label: 'Home', url: '/' },
                        { label: data?.partner_details?.title || 'Leoni', onClick: handleBreadcrumbClick },
                        { label: `${slug}`, url: `/product-detail/${slug}` }
                    ]} />

                </div>
            </section>
            <div className="c-container">
                <section className="product-detail-section" ref={sectionRef}>
                    {
                        loading ? <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                        /> :
                            <>
                                <div className="product-img-container">
                                    <Slider {...settings}>
                                        {loading ? <ColorRing
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                                        /> : data?.gallery?.map((el) => {
                                            return (
                                                <div key={el.id}>
                                                    <div className="product-img product-img-2">
                                                        <LazyLoadImage
                                                            src={el?.image}
                                                            alt=""
                                                            effect="blur"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Slider>

                                </div>
                                <div className="product-info">
                                    <h1 className='product-detail-title'>{data?.title}</h1>
                                    <div className='product-description' dangerouslySetInnerHTML={{ __html: `${data?.description}` }} />

                                    <div className="product-detail-btns">
                                        {data?.catalogue && (
                                            <Link target='_blank' to={data?.catalogue} style={{ textDecoration: "none" }}>
                                                <button variant="primary" className="common-btn-blue btn-flex">
                                                    Datasheet <MdDownload size={20} style={{ marginLeft: "5px" }} />
                                                </button>
                                            </Link>
                                        )}

                                        <button variant="primary" onClick={openModal} className="common-btn btn-flex p-detail-btn" >
                                            Get Quote <IoIosArrowForward size={20} style={{ marginLeft: "5px" }} />
                                        </button>

                                        <div className="modal" style={{ display: isModalOpen ? "block" : "none" }} onClick={handleOverlayClick}>
                                            <div className="modal-diologue">
                                                <div className="modal-container">

                                                    <div className="modal-header">
                                                        <p className='m-h'>Service Request</p>
                                                        <button className="close-button" onClick={closeModal}><RxCross2 /></button>
                                                    </div>
                                                    <div className="popup-modal">
                                                        <div className="modal-content">
                                                            <form onFocus={handleFormInteraction} onClick={handleFormInteraction} onSubmit={handleSubmit(onSubmit)} className="vertical-form">

                                                                <div className="form-container form-container2">
                                                                    <div className="form-group">
                                                                        <label>Your Name</label>
                                                                        <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                                                        {errors.name && <div className="error-message">{errors.name.message}</div>}
                                                                    </div>


                                                                    <div className="form-group">
                                                                        <label>Your Email</label>
                                                                        <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                                                        {errors.email && <div className="error-message">{errors.email.message}</div>}
                                                                    </div>


                                                                    <div className="form-group">
                                                                        <label>Your Number</label>
                                                                        <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                                                            required: 'Required', pattern: {
                                                                                value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                                                message: 'Invalid phone number',
                                                                            }
                                                                        })} />
                                                                        {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="typeSelect">Select Type</label>
                                                                        <Controller
                                                                            name="type"
                                                                            defaultValue={""}
                                                                            control={control}
                                                                            rules={{
                                                                                required: 'Please select a type'
                                                                            }}

                                                                            render={({ field }) => (
                                                                                <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                                                                                    <option value="" disabled>Select a type</option>
                                                                                    <option value="dealer">Dealer</option>
                                                                                    <option value="retailer">Retailer</option>
                                                                                    <option value="distributor">Distributor</option>
                                                                                    <option value="epc player">EPC Player</option>
                                                                                    <option value="consumer">Consumer</option>
                                                                                </select>
                                                                            )}
                                                                        />
                                                                        {errors.type && <div className="error-message">{errors.type.message}</div>}
                                                                    </div>



                                                                    <div className="form-group">
                                                                        <label htmlFor="stateSelect">State</label>
                                                                        <Controller
                                                                            name="state"
                                                                            defaultValue={""}
                                                                            control={control}
                                                                            rules={{ required: 'Please select a state' }}
                                                                            render={({ field }) => (
                                                                                <select {...field} className='form-control' onChange={(e) => setValue('state', e.target.value)}>
                                                                                    <option value="" disabled>Select a state</option>
                                                                                    {states?.map((el) => {
                                                                                        return (
                                                                                            <option key={el?.id} value={el?.id}>
                                                                                                {el?.state}
                                                                                            </option>
                                                                                        )
                                                                                    })}

                                                                                </select>
                                                                            )}
                                                                        />
                                                                        {errors.state && <div className="error-message">{errors.state.message}</div>}
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="citySelect">City</label>
                                                                        <Controller
                                                                            name="city"
                                                                            defaultValue={""}
                                                                            control={control}
                                                                            rules={{ required: 'Please select a city' }}
                                                                            render={({ field }) => (
                                                                                <select {...field} className='form-control' onChange={(e) => setValue('city', e.target.value)}>
                                                                                    <option value="" disabled>Select a city</option>
                                                                                    {city?.map((el) => {
                                                                                        return (
                                                                                            <option key={el?.id} value={el?.id}>
                                                                                                {el?.city_name}
                                                                                            </option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            )}
                                                                        />
                                                                        {errors.city && <div className="error-message">{errors.city.message}</div>}
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Product Name</label>
                                                                        <Controller
                                                                            name='product_name'
                                                                            defaultValue={product}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    {...field}
                                                                                    readOnly
                                                                                    className='form-control'
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errors.product_name && <div className="error-message">{errors.product_name.message}</div>}

                                                                        <Controller
                                                                            name='product_id'
                                                                            control={control}
                                                                            defaultValue={productId}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    {...field}
                                                                                    type="hidden"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Quantity</label>
                                                                        <input
                                                                            type="tel"
                                                                            className="form-control"
                                                                            placeholder="Enter Quantity"
                                                                            {...register('quantity', {
                                                                                required: 'Quantity is required',
                                                                                pattern: {
                                                                                    value: /^[1-9]\d*$/,
                                                                                    message: 'Invalid quantity, must be a positive number',
                                                                                },
                                                                                min: {
                                                                                    value: 1,
                                                                                    message: 'Quantity must be at least 1',
                                                                                },
                                                                            })}
                                                                        />
                                                                        {errors.quantity && <div className="error-message">{errors.quantity.message}</div>}
                                                                    </div>

                                                                    <div className="form-group text-area-group" >
                                                                        <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                                                        {errors.message && <div className="error-message">{errors.message.message}</div>}
                                                                    </div>
                                                                    <input type="hidden" {...register('enquiry_type')} />
                                                                    <div className="form-group">
                                                                        {showReCAPTCHA && (
                                                                            <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                                                                                <ReCAPTCHA
                                                                                    sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                                                                    onChange={handleVerificationSuccess}
                                                                                />
                                                                            </Suspense>
                                                                        )}
                                                                        {captchaError && <div className="error-message">{captchaError}</div>}
                                                                    </div>

                                                                </div>
                                                                <div className="form-footer">
                                                                    <button className='common-btn enquiry-btn' type="submit">Submit Request</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                    }
                </section>
            </div>
            <section className="related-product-section">
                <div className="c-container">
                    <h2>Related Products</h2>
                    <div className="related-products">
                        {
                            loading ? <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                            /> :
                                data?.related?.map((el, index) => {
                                    return (
                                        <div className="products-item" key={index} onClick={() => {
                                            scrollTotop()
                                        }}>
                                            <Link to={`/product-detail/${el?.slug}`}>
                                                <div className="products-item-img">
                                                    <LazyLoadImage src={el?.image} alt="" effect='blur' />
                                                </div>
                                                <h5>{el?.title}</h5>
                                            </Link>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </section >
        </>
    )
}

export default ProductDetail